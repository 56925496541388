import { useEffect, useState, useRef } from "react"

export const useMousePosition = ref => {
  const [position, setPosition] = useState({ x: 80, y: 80, distance: 0 })
  let collision = useRef(false)

  useEffect(() => {
    const setFromEvent = e => {
      const { innerHeight: yMax, innerWidth: xMax } = window
      const {
        height: btnH,
        width: btnW,
        x: btnX,
        y: btnY
      } = ref.current.getBoundingClientRect()

      const btnCenter = {
        x: btnX + btnW / 2,
        y: btnY + btnH / 2
      }

      const distance = Math.sqrt(
        (e.x - btnCenter.x) * (e.x - btnCenter.x) +
          (e.y - btnCenter.y) * (e.y - btnCenter.y)
      )

      let newPos = { x: btnX, y: btnY }

      if (distance < btnW * 2.5) {
        if (!collision.current) {
          collision.current = true
        }
        newPos.x = btnX + (btnCenter.x - e.x) / (distance * 0.1)
        if (newPos.x < 0) newPos.x = 0
        else if (newPos.x > xMax - btnW) newPos.x = xMax - btnW
        newPos.y = btnY + (btnCenter.y - e.y) / (distance * 0.1)
        if (newPos.y < 0) newPos.y = 0
        else if (newPos.y > yMax - btnW) newPos.y = yMax - btnW
      } else if (collision.current) {
        collision.current = false
      }
      setPosition({ ...newPos, distance })
    }
    window.addEventListener("mousemove", setFromEvent)

    return () => {
      window.removeEventListener("mousemove", setFromEvent)
    }
  })

  return position
}
