import React from "react"
import styled from "styled-components"

import { FaGithubAlt } from "react-icons/fa"

const StyledFooter = styled.footer`
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--HORIZON);
  color: var(--MUZZLEWHITE);

  a {
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Footer = props => {
  return (
    <StyledFooter>
      <a href="https://github.com/Sylv0">
        <FaGithubAlt />
        Github
      </a>
    </StyledFooter>
  )
}

export default Footer
