import React, { useContext } from "react"
import { Context } from "../store"
import { Redirect } from "react-router-dom"

const Colors = props => {
  const {
    store: { navbarUnlocked, colorMain, colorScnd, colorThrd },
    dispatch
  } = useContext(Context)

  const changeColor = ({ target: { value } }, target) => {
    dispatch({
      type: "updateColor",
      target,
      value
    })
  }

  if (!navbarUnlocked) return <Redirect to="/"></Redirect>

  return (
    <header className="App-header">
      <p style={{ maxWidth: "50%" }}>
        Although I am not much in the way of a designer, I do still enjoy
        playing around with CSS. The one thing in the current version of CSS I
        very much like is root-variables.
      </p>
      <p style={{ maxWidth: "50%" }}>
        The inputs below are connected to the variables used in the CSS of this
        site, and changing them will update every component that use them.
      </p>
      <input
        type="color"
        value={colorMain}
        onChange={e => changeColor(e, "Main")}
      />
      <input
        type="color"
        value={colorScnd}
        onChange={e => changeColor(e, "Scnd")}
      />
      <input
        type="color"
        value={colorThrd}
        onChange={e => changeColor(e, "Thrd")}
      />
    </header>
  )
}

export default Colors
