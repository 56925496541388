import React, { useContext } from "react"

import { Context } from "../store"

import HomeButton from "../components/HomeButton"

const linkStyle = {
  color: "var(--MUZZLEWHITE)",
  textDecoration: "none",
  fontWeight: "none",
  cursor: "inherit"
}

const About = props => {
  const {
    store: { navbarUnlocked },
    dispatch
  } = useContext(Context)
  return (
    <header className="App-header">
      <h1>About me</h1>
      <p>Web developer with a higher vocational education.</p>
      <p>
        Enjoy complicating things, such as a{" "}
        {navbarUnlocked ? (
          "word"
        ) : (
          <a
            href="/"
            onClick={e => {
              e.preventDefault()
              dispatch({ type: "unlockNavbar" })
            }}
            style={linkStyle}
          >
            word
          </a>
        )}{" "}
        that can be clicked to unlock the navbar. <br /> Sometimes for personal
        advancement, other times for messing with people.
      </p>
      <p>Work with full stack, but not with making things look good.</p>
      <HomeButton />
    </header>
  )
}

export default About
