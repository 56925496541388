import React, { useReducer } from "react"
import { Context, initialState, reducer } from "./store"
import "./App.css"

import { Switch, Route } from "react-router-dom"

import Navbar from "./components/Navbar"

import Front from "./pages/Front"
import About from "./pages/About"
import Colors from "./pages/Colors"
import Footer from "./components/Footer"
import { MobileOnlyView } from "react-device-detect"

function App() {
  const [store, dispatch] = useReducer(reducer, initialState)

  document.documentElement.style.setProperty("--NIGHTOPS", store.colorMain)
  document.documentElement.style.setProperty("--HORIZON", store.colorScnd)
  document.documentElement.style.setProperty("--DUSTYGREY", store.colorThrd)

  return (
    <Context.Provider value={{ store, dispatch }}>
      <main className="App">
        {store.navbarUnlocked ? <Navbar /> : <></>}
        <Switch>
          <Route path="/colors" component={Colors} />
          <Route path="/about" component={About} />
          <Route path="/" component={Front} />
        </Switch>
        <MobileOnlyView viewClassName="bottom_msg">
          Mobile user eh? Should test on desktop aswell.
        </MobileOnlyView>
        <Footer />
      </main>
    </Context.Provider>
  )
}

export default App
