import React from "react"

const defaultState = {
  navbarUnlocked: false,
  colorMain: getComputedStyle(document.documentElement).getPropertyValue(
    "--NIGHTOPS"
  ),
  colorScnd: getComputedStyle(document.documentElement).getPropertyValue(
    "--HORIZON"
  ),
  colorThrd: getComputedStyle(document.documentElement).getPropertyValue(
    "--DUSTYGREY"
  )
}

export const initialState =
  JSON.parse(localStorage.getItem("progress")) || defaultState

export const reducer = (state, action) => {
  let newState = JSON.parse(localStorage.getItem("progress")) || state
  switch (action.type) {
    case "reset":
      console.log("reset")

      localStorage.removeItem("progress")
      newState = defaultState
      break
    case "unlockNavbar":
      newState = {
        ...state,
        navbarUnlocked: true
      }
      break
    case "updateColor":
      newState = {
        ...state,
        [`color${action.target}`]: action.value
      }
      break
    default:
      newState = state
      break
  }

  localStorage.setItem("progress", JSON.stringify(newState))
  return newState
}

export const Context = React.createContext()
