import React, { useContext } from "react"
import { Context } from "../store"
import { Link, Redirect } from "react-router-dom"
import styled from "styled-components"

const NavbarComp = styled.nav`
  position: fixed;
  width: 100%;
  background-color: var(--HORIZON);
  height: 56px;

  .spacer {
    flex: 1;
  }

  .toolbar__navigation {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
  }

  .toolbar__logo {
    margin-left: 0.5rem;
    a {
      color: var(--DUSTYGREY);
      text-decoration: none;
      font-size: 2rem;
    }
  }

  .toolbar_navigation-items {
    width: 100%;
    a {
      color: var(--MUZZLEWHITE);
      text-decoration: none;

      :hover,
      :active {
        color: var(--NIGHTOPS);
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        padding: 0 0.5rem;
        &.nav_item-right {
          justify-self: flex-end;
        }
      }
    }
  }
`

const Navbar = props => {
  const { dispatch } = useContext(Context)
  return (
    <NavbarComp>
      <div className="toolbar__navigation">
        <div className="toolbar_navigation-items">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li style={{ flex: 1 }}>
              <Link to="/colors">CSS</Link>
            </li>
            <li className="nav_item-right">
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()
                  dispatch({ type: "reset" })
                  return <Redirect to="/" />
                }}
              >
                Reset Site
              </a>
            </li>
          </ul>
        </div>
      </div>
    </NavbarComp>
  )
}

export default Navbar
