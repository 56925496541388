import React from "react"

import { FaHome } from "react-icons/fa"
import { Link } from "react-router-dom"

const HomeButton = props => {
  return (
    <Link to="/">
      <FaHome />
    </Link>
  )
}

export default HomeButton
