import React, { useContext } from "react"
import { Context } from "../store"
import { FaRegQuestionCircle } from "react-icons/fa"
import { Link } from "react-router-dom"
import { BrowserView } from "react-device-detect"
import TrollButton from "../components/TrollButton"
const Front = props => {
  const {
    store: { navbarUnlocked }
  } = useContext(Context)
  return (
    <section className="App-header">
      <h1>Johan Sylvan</h1>
      <p>
        Full stack web <b>developer</b>,
      </p>
      <p>not a designer.</p>
      {!navbarUnlocked && (
        <Link to="/about">
          <FaRegQuestionCircle />
        </Link>
      )}
      <BrowserView>
        <TrollButton />
      </BrowserView>
    </section>
  )
}

export default Front
