import React, { useState, useRef } from "react"
import styled from "styled-components"
import { useMousePosition } from "../utils/useMousePosition"

const Button = styled.button`
  position: fixed;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  &:focus {
    outline: none;
  }
`

const TrollButton = () => {
  const _btnElmnt = useRef(null)
  const position = useMousePosition(_btnElmnt)

  const audio = useRef(null)

  const [background] = useState("var(--HORIZON)")
  return (
    <React.Fragment>
      <Button
        ref={_btnElmnt}
        style={{ top: `${position.y}px`, left: `${position.x}px`, background }}
        onClick={() => {
          audio.current.play()
        }}
      >
        Secret Button?
      </Button>
      <audio ref={audio}>
        <source
          src="/lego-yoda-death-sound-effect.mp3"
          type="audio/mpeg"
        ></source>
      </audio>
    </React.Fragment>
  )
}

export default TrollButton
